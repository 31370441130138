import { mapActions, mapGetters } from 'vuex';

import { Loader } from '@/components';

import isServer from '@/utils/isServer';

if (!isServer) {
  const { required, email, confirmed } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('confirmed', {
    ...confirmed,
    message: 'De wachtwoorden komen niet overeen.',
  });

  extend('verify_password', {
    // eslint-disable-next-line no-unused-vars
    message: 'Wachtwoord moet ten minste 8 tekens zijn en bestaan uit ten minste één hoofdletter, één speciaal teken & één cijfer.',
    validate: (value) => {
      // eslint-disable-next-line no-useless-escape
      const strongRegex = new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$');

      // the regex above checks if the value is less than 8, doesn't include upper/lowercase characters and it doesn't have a symbol
      // if it is false, your password is valid because it didn't trigger the faultregex
      return !strongRegex.test(value);
    },
  });
}

export default {
  name: 'ResetPassword',
  components: {
    [Loader.name]: Loader,
  },
  metaInfo: {
    title: 'Reset password page',
  },
  props: {},
  data: () => ({
    userObj: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    showForm: true,
  }),
  computed: {
    ...mapGetters('account', {
      isLoadingResetPassword: 'getLoadingStateResetPassword',
    }),
  },
  methods: {
    ...mapActions('account', {
      resetPassword: 'RESET_PASSWORD',
    }),
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);
            if (!isServer) {
              this.$refs.form.refs[errors[0]].$el.scrollIntoView();
              window.scrollBy(0, -75);
            }
          }, 100);
        } else {
          this.userObj.token = this.$route.query.token;
          this.resetPassword(this.userObj)
            .then(() => {
              this.showForm = false;
            })
            .catch((error) => {
              this.$toasted.show(...error.response.data);
            });
        }
      });
    },
  },
  created() {
    if (!this.$route.query.token) {
      this.$router.push({ name: '404' });
    }
  },
};
